import React from 'react'
import '../../styles/global.scss'

export interface LayoutProps {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

const Layout = ({ className, children }: LayoutProps): JSX.Element => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <div
        className={`font-serif text-dark-blue px-6 sm:px-12 md:px-20 ${className}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
